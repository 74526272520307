import * as React from "react";
import {
  Datagrid,
  TextField,
  SelectInput,
  ReferenceInput,
  RecordContextProvider,
  DatagridBody,
  useRecordContext,
  AutocompleteInput,
  AutocompleteArrayInput,
  InfiniteList,
  useListContext,
  InfinitePagination,
} from "react-admin";
import {
  TableCell,
  TableRow,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import { AkLinkField } from "./AdminField";

let cntCur = 0;

const MyDatagridRow = ({
  record,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
}) => {
  return (
    <RecordContextProvider value={record}>
      {record.id !== "Total" && (
        <TableRow>
          <TableCell padding="none">
            <Checkbox
              disabled={selectable}
              checked={selected}
              onClick={(event) => onToggleItem(id, event)}
            />
          </TableCell>
          {React.Children.map(children, (field) => {
            return (
              <TableCell
                key={`${id}-${field.props.source}`}
              >
                {field}
              </TableCell>
            );
          })}
        </TableRow>
      )}
    </RecordContextProvider>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);

const MyDatagrid = (props) => <Datagrid {...props} body={<MyDatagridBody />} />;

const CurrentIndexField = (props) => {
  const record = useRecordContext(props);
  if (record.id == "Total" || record.id == 0) {
    cntCur = 0;
    return "";
  }
  return <span>{(cntCur = cntCur + 1)}</span>;
};

const PercentageField = ({ source }) => {
  const record = useRecordContext();
  return <span>{record[source]}%</span>;
};
const yearFilter = () => {
  const curYear = parseInt(new Date().getFullYear());
  const yearLimit = [curYear - 10, curYear + 10];
  let years = [
    { id: "this", name: "Year until Today" },
    { id: "last", name: "Last Year" },
  ];
  for (let i = yearLimit[0]; i <= yearLimit[1]; i++) {
    years.push({ id: i, name: i });
  }
  return years;
};
const projectFilters = [
  <ReferenceInput source="regionId" reference="reportRegions" alwaysOn>
    <AutocompleteInput optionText="name" label="Region" />
  </ReferenceInput>,
  <SelectInput
    source="month"
    label="Month"
    alwaysOn
    choices={[
      { id: "01", name: "January" },
      { id: "02", name: "February" },
      { id: "03", name: "March" },
      { id: "04", name: "April" },
      { id: "05", name: "May" },
      { id: "06", name: "June" },
      { id: "07", name: "July" },
      { id: "08", name: "August" },
      { id: "09", name: "September" },
      { id: "10", name: "October" },
      { id: "11", name: "November" },
      { id: "12", name: "December" },
    ]}
  />,
  <SelectInput source="year" label="Year" alwaysOn choices={yearFilter()} />,
  <ReferenceInput
    source="zustandig"
    label="Zuständig"
    alwaysOn
    reference="workers"
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    source="kunde"
    label="Kunde"
    reference="kundenlistes"
    alwaysOn
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <AutocompleteArrayInput
    source="farbe"
    alwaysOn
    label="Farbe (color)"
    className="project-color-filter"
    choices={[
      { id: 0, name: <span className="farbess farbe0">.</span> },
      { id: 1, name: <span className="farbess farbe1">.</span> },
      { id: 2, name: <span className="farbess farbe2">.</span> },
      { id: 3, name: <span className="farbess farbe3">.</span> },
      { id: 4, name: <span className="farbess farbe4">.</span> },
      { id: 5, name: <span className="farbess farbe5">.</span> },
      { id: 6, name: <span className="farbess farbe6">.</span> },
      { id: 7, name: <span className="farbess farbe7">.</span> },
      { id: 8, name: <span className="farbess farbe8">.</span> },
      { id: 9, name: <span className="farbess farbe9">.</span> },
    ]}
  />,
  <SelectInput
    source="gewerk"
    label="Gewerk"
    alwaysOn
    choices={[
      { id: "DE", name: "DE" },
      { id: "DE+TR", name: "DE+TR" },
      { id: "TR", name: "TR" },
      { id: "FM", name: "FM" },
      { id: "LO", name: "LO" },
      { id: "SchU", name: "SchU" },
      { id: "WHST", name: "WHST" },
      { id: "Sonstige", name: "Sonstige" },
      { id: "Rekla", name: "Rekla" },
    ]}
  />,
  <ReferenceInput
    source="account_manager_id"
    label="Kundenbetreuer"
    alwaysOn
    reference="users"
  >
    <AutocompleteInput optionText="name" label="Kundenbetreuer" />
  </ReferenceInput>,
];
const CustomPagination = () => {
  const { total, data, isPending } = useListContext();

  // Check if data exists and is an array

  if (!isPending) {
    if (!isPending && !Array.isArray(data)) {
      return <div>No data available</div>; // Return early if data is invalid
    }
    // Try to find the object with id "Total"
    const foundTotalObject = data.find((item) => item.id === "Total");
    return (
      <>
        <InfinitePagination sx={{ padding: 0 }} />
        {total > 0 && foundTotalObject && (
          <Box
            position="sticky"
            bottom={0}
            textAlign="center"
            sx={{
              display: "flex",
              p: 2,
              paddingLeft: 12,
              paddingRight: "20px",
              justifyContent: "space-between",
              width: "100%",
              backgroundColor: "green",
              color: "white",
            }}
          >
            <Typography variant="body2">{foundTotalObject.id}</Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "40px",
              }}
            >
              <Typography variant="body2">
                <span style={{ color: "#add8ad" }}>Umsatz:</span>{" "}
                {foundTotalObject.umsatz}
              </Typography>
              <Typography variant="body2">
                <span style={{ color: "#add8ad" }}>Deckungsbeitrag:</span>{" "}
                {foundTotalObject.deckungsbeitrag}
              </Typography>
              <Typography variant="body2">
                <span style={{ color: "#add8ad" }}>Percentage: </span>{" "}
                {foundTotalObject.percentage}%
              </Typography>
            </div>
          </Box>
        )}

        {}
      </>
    );
  }
};

export const ReportList = () => (
  <InfiniteList
    filters={projectFilters}
    perPage={50}
    debounce={500}
    pagination={<CustomPagination />}
  >
    <MyDatagrid>
      <CurrentIndexField label="#" />
      <TextField source="id" label="Auftragsnummer" />
      <TextField source="auftragseingang" label="Auftragseingang" />
      <AkLinkField source="k_" res="kundenlistes" label="Kunde" />
      <TextField source="adresse" label="Adresse" />
      <TextField source="gewerk" label="Gewerk" />
      <TextField source="umsatz" label="Umsatz" />
      <TextField source="deckungsbeitrag" label="Deckungsbeitrag" />
      <PercentageField label="%" source={"percentage"} />
    </MyDatagrid>
  </InfiniteList>
);